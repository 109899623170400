import React from 'react'

import {
  styled,
  createTheme,
  Switch as MuiSwitch,
  SwitchProps,
  ThemeProvider,
  colors,
} from '@mui/material'

const { green } = colors

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: green[500],
      light: green[300],
      dark: green[700],
    },
    secondary: {
      main: '#6b7280',
    },
    success: {
      main: green[500],
    },
    error: {
      main: '#ef4444',
    },
  },
})

const Switch = styled((props: SwitchProps) => (
  <ThemeProvider theme={theme}>
    <MuiSwitch
      focusVisibleClassName=".Mui-focusVisible"
      className="!transition-all !duration-700"
      disableRipple
      {...props}
    />
  </ThemeProvider>
))(({ size }: { size?: string }) => ({
  padding: 8, // Apply default padding

  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    opacity: 1,
    backgroundColor: 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
    '&:before, &:after': {
      transitionDuration: '800ms',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
    },
    '&:before': {
      left: 12,
    },
    '&:after': {
      right: 12,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
  },

  // Additional style for small size
  ...(size === 'small' && {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 15,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(12px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          //  backgroundColor: '#1890ff',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: 'rgba(0,0,0,.25)',
      boxSizing: 'border-box',
    },
  }),
}))

export default Switch
